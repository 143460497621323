<template>
  <div>
    <b-table
      :data="rows"
      bordered
      narrowed
      :loading="loading_table"
    >
      <template #empty>
        <p class="m-5 p-5 has-text-danger has-text-centered" v-show="!loading_table">
          No hay registros para mostrar
        </p>
      </template>
      <b-table-column
        label="#"
        width="20"
        v-slot="{ index }"
        header-class="has-background-grey-dark has-text-white-bis"
      >
        {{ index + 1 }}
      </b-table-column>
      <b-table-column
        label="Cuenta"
        v-slot="{ row }"
        header-class="has-background-grey-dark has-text-white-bis"
      >
        <a :href="row.url" :title="row.url" target="_blank">
          {{ row.url }}
        </a>
      </b-table-column>
      <b-table-column
        centered
        width="200"
        header-class="has-background-grey-dark has-text-white-bis"
        v-slot="{row}"
      >
        <b-button
          icon-left="edit"
          type="is-warning is-light"
          size="is-small"
          @click="edit(row)"
          title="Editar"
          class="mr-2"
        >Editar</b-button>
        <b-button
          icon-left="trash"
          type="is-danger is-light"
          size="is-small"
          @click="destroy(row)"
          title="Eliminar"
        >Eliminar</b-button>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading_table: false,
    };
  },
  computed:{
    rows(){
      return this.$store.getters[`dashboard/rows`]
    },
  },
  created() {
    this.loadData()
  },
  methods:{
    async loadData(){
      this.loading_table = true
      await this.$store.dispatch(`dashboard/GetRequest`)
      this.loading_table = false
    },
    async edit(row){
      this.$router.push({ name: `cuentas-nuevo` , query: {id : row.domain}})
    },
    async destroy(row){
      if (confirm(`Realmente desea eliminar la cuenta "${row.url}"?`)) {
        await this.$store.dispatch(`dashboard/DeleteRequest`, row.domain)
        this.loadData()
      }
    },
  }
};
</script>