<template>
  <div>
    <title-bar :items="[{ title }]">
      <template #buttonsleft>
        <button-header 
          tag="router-link"
          :to="{ name: `cuentas-nuevo` }"
          icon-left="plus">
          Nuevo
        </button-header>     
      </template>
    </title-bar>
    <TableList />
  </div>
</template>

<script>
import TableList from '../components/TableList.vue'
export default {
  components: {
    TableList,
  },
  computed:{
    title(){
      return this.$route.meta.title || "";
    }
  }
};
</script>
